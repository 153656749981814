<template>

  <div :class="{'card card-template mb-0': true, 'cursor-pointer': !used}" @mouseenter="hovered = true" @mouseleave="hovered = false" @click="!used ? useTemplate(template) : null">
    <div class="card-header pb-0">
      <div class="">
        <i :class="`icon-${template.icon} text-primary mr-2`" />
        <span class="font-weight-500">{{ template.entity_label }}</span>
      </div>
    </div>
    <div class="card-body pt-2 pb-3">
      <h4 class="my-0 font-weight-500">
        {{ template.name }}
      </h4>
      <p class="mt-2 text-grey" v-if="template.description">{{ template.description }}</p>
    </div>
    <div v-if="used && !loading" class="py-2 card-footer bg-success border-success">
      <div class="font-weight-bold text-center">
        <i class="icon-check2 mr-1" />
        {{
          this.$t('modules.templates.card.used', {
            attraction: template.entity_label,
          })
        }}
      </div>
    </div>
    <div v-else-if="!loading" :class="{
      'transition card-footer py-2 text-right text-grey': true,
      'bg-success text-white border-success': hovered,
    }">
      <div class="text-uppercase small font-weight-bold">
        <i class="icon-versions mr-1" />
        {{ $t('modules.templates.card.use_template') }}
      </div>
    </div>
    <div v-else class="py-2 card-footer text-grey small">
        <loader class="text-primary mr-1" />
        {{ $t('modules.templates.card.loading') }}
      </div>
    </div>
  </div>

</template>

<script>
import _ from 'lodash'

import {
  TEMPLATES_USE,
} from '../mutations-types'

import {
  WIKI_USE_CONTENT_TEMPLATE,
  WIKI_USE_ONBOARDING_TEMPLATE,
} from '../../Wiki/mutations-types'

export default {
  name: 'TemplateCard',
  props: {
    template: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
      used: false,
      hovered: false,
      loading: false,
    }
  },
  mounted () {
  },
  methods: {
    useTemplate (template) {

      let storeModule = 'templates'
      let storeMethod = TEMPLATES_USE

      // Process is different for wiki content
      if (template.module == 'wiki' && template.entity == 'Content') {
        storeModule = 'wiki'
        storeMethod = WIKI_USE_CONTENT_TEMPLATE
      }
      // Process is different for wiki onboarding
      if (template.module == 'wiki' && template.entity == 'Onboarding') {
        storeModule = 'wiki'
        storeMethod = WIKI_USE_ONBOARDING_TEMPLATE
      }

      if (this.loading) {
        return
      }
      this.loading = true
      this.$store.dispatch(storeModule + '/' + storeMethod, {
        worldId: this.$route.params.world,
        templateId: template.id,
      }).then(template => {
        this.loading = false
        // this.$router.push({name: this.currentType + '.template.page', params: { world: this.$route.params.world, template: template.id}})
        this.notifSuccess(this.$t('modules.templates.card.cloned_success', {
          name: this.template.name,
          attraction: this.template.entity_label,
        }))
        this.used = true
      })
      .catch(error => {
        this.loading = false
        this.notifError(error)
      })
    },
  },
  computed: {
  },
}
</script>

<style lang="scss" scoped>
.card-template {
  min-height: 100%;
}
.font-weight-500 {
  font-weight: 500;
}
</style>
