import _ from 'lodash'

export default {
  methods: {
    formatTemplates (templates) {
      let contentArray = []

      if (templates) {

        // Get templates
        _.map(templates, (elem) => {
          let moduleEntityLabel = this.$t(`modules.${elem.module}.title`)
          let moduleIcon = this.moduleEntitiesIcons[elem.module]['entities'][elem.entity] ? this.moduleEntitiesIcons[elem.module]['entities'][elem.entity].icon : this.moduleEntitiesIcons[elem.module].icon

          // Special treatment for Wiki/Onboarding, as it is the same module
          if (elem.module == 'wiki' && elem.entity == 'Onboarding') {
            moduleEntityLabel = this.$t(`modules.${elem.module}.onboarding.title`)
            moduleIcon = this.$router.resolve({name: `wiki.onboarding`}).route.meta.icon
          }

          // Special treatment for Process/Forms, as it is the same entity type
          if (elem.module == 'process' && elem.entity == 'ProcessTemplate' && elem.type) {
            moduleEntityLabel = this.$t(`modules.${elem.module}.${elem.type}.title`)
            moduleIcon = this.$router.resolve({name: `${elem.type}.template.index`}).route.meta.icon
          }

          let content = {
            id: elem.id,
            icon: moduleIcon,
            name: elem.name,
            module: elem.module,
            entity: elem.entity,
            entity_label: moduleEntityLabel,
            description: elem.description,
          }
          contentArray.push(content);
        })
      }
      return contentArray
    }
  },
  computed: {
    modules () {
      return this.$store.getters['world/getModules']
    },
    moduleEntitiesIcons () {
      let defaultIcon = 'versions'
      let icons = {
        'wiki': {
          icon: this.$router.resolve({name: 'wiki.index'}).route.meta.icon,
          entities: {},
        },
      }
      _.forEach(this.modules, (moduleData, moduleAlias) => {
        icons[moduleAlias] = {
          icon: moduleData.icon || defaultIcon,
          entities: {},
        }
        if (moduleData.pages) {
          _.forEach(moduleData.pages, (pageData) => {
            icons[moduleAlias].entities[pageData.render.data] = {
              icon: pageData.icon || defaultIcon
            }
          })
        }
      })
      return icons
    },
  },
}
