<template>

    <auth-content>
      <el-header :title="$t(`modules.templates.index.title`)" slot="header" />
      <div v-if="templates && Object.keys(templates).length > 0">

        <el-alert type="success" v-if="templates && Object.keys(templates).length > 0">
          {{ $t('modules.templates.index.search_nb', {'nb': Object.keys(templates).length}) }}
        </el-alert>

        <el-filters :filters="filters"/>

        <div class="row">
          <div :key="`${content.module}-${content.id}`" v-for="content in contentFiltered" class="col-xxl-3 col-xl-4 col-lg-6 col-xs-12 pb-3">
            <templates-template-card
              :template="content"
            />
          </div>
          <div class="col-xxl-3 col-xl-4 col-lg-6 col-xs-12 pb-3" v-if="Object.keys(contentFiltered).length == maxTemplatesToShow && Object.keys(templates).length > maxTemplatesToShow">
            <div class="p-3 bg-light text-info font-weight-bold">
              <i class="icon-search4 mr-1"/> {{ $t('modules.templates.index.more') }}
            </div>
          </div>
        </div>

        <div class="small text-grey mb-5" v-if="Object.keys(contentFiltered).length > 0">
          {{ $t('modules.templates.index.bottom_text') }}
        </div>

      </div>

      <el-empty v-else :title="$t(`modules.templates.index.empty_title`)" :text="$t(`modules.templates.index.empty_text`)"></el-empty>

    </auth-content>

</template>

<script>

import store from '@/store'
import Fuse from 'fuse.js'
import _ from 'lodash'
import TemplatesHelper from '../helpers/TemplatesHelper'
import TemplateCardComponent from '../components/TemplateCard'

import {
  TEMPLATES_GET_LIST,
} from '../mutations-types'

export default {
  extends: TemplatesHelper,
  name: 'TemplatesIndex',
  components: {
    "templates-template-card": TemplateCardComponent,
  },
  data () {
    return {
      templates: false,
      loading: false,
      filters: false,
    }
  },
  mounted () {
  },
  methods: {
    loadFilters () {
      let content = {...this.formatTemplates(this.templates)}
      this.filters = Object.assign({}, this.filters, [
        {
          title: this.$t('modules.templates.index.filters.entity'),
          key: 'entity_label',
          options: this.getFilterOptions(content, 'entity_label'),
        },
      ])
    },
  },
  computed: {
    currentType () {
      return this.$route.path.split('/')[3]
    },
    worldCanManageTemplates () {
      return this.$store.getters['auth/worldCanManageTemplates']
    },
    maxTemplatesToShow () {
      return this.worldCanManageTemplates ? 1000 : 9
    },
    searchValue: {
      get () {
        return this.$store.state.global.sidebarSearch
      },
      set (value) {
        this.$store.commit('global/changeSidebarSearch', value)
      }
    },
    contentFormatted () {
      return this.formatTemplates(this.templates)
    },
    contentFiltered () {

      if (!this.filters) {
        return []
      }
      if (this.searchValue === '') {
        return this.applyDataFilters(this.contentFormatted, this.filters).slice(0, this.maxTemplatesToShow)
      }

      return new Fuse(this.applyDataFilters(this.contentFormatted, this.filters), {
        shouldSort: true,
        threshold: 0.3,
        location: 0,
        distance: 100,
        minMatchCharLength: 1,
        keys: [
          "name",
          "entity_label"
        ]
      }).search(this.searchValue).slice(0, this.maxTemplatesToShow)
    },
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('templates/' + TEMPLATES_GET_LIST, to.params.world).then(templates => {
      next(vm => {
        vm.templates = templates
        vm.loadFilters()
      })
    })
  }
}
</script>

<style lang="scss" scoped>
.card-img-top {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
}
</style>
